<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="event">
      <v-alert type="warning" v-if="event.notPublished">{{$t('eventmanager.not-published-msg')}}</v-alert>
      <EventHeader :event="event"/>

      <v-card-title>{{$t('events.welcome.enter-verification-code')}}</v-card-title>
      <v-card-text v-if="status && status.verification_required">
        <v-alert v-if="event.join_msg" type="info">
          <vue-markdown class="markdown" :html="false" :source="event.join_msg" />
        </v-alert>
        <v-alert v-else-if="!hideVerificationCode" type="info">
          {{$t('events.welcome.verification-code-message')}}
        </v-alert>

        <p v-if="event.verification_method === 'REG_DATA'">
          {{$t('events.welcome.verification-code-description')}}
        </p>

      </v-card-text>
      <v-alert v-if="!$store.getters.isLoading && result && result.status == 'ERROR'" type="error" class="mx-4">
        <p>{{ result.msg }}</p>
        <p>{{$t('events.welcome.join-error')}}</p>
      </v-alert>

      <v-form v-if="status" ref="form" v-model="valid" @submit.prevent="joinEvent" lazy-validation class="mx-4 mb-4">
          <v-text-field 
              v-if="status.verification_required && !hideVerificationCode"
              v-model="request.code" 
              :rules="codeRules" 
              :label="$t('events.welcome.verification-code-label')" 
              :required="status.verification_required">
          </v-text-field>

          <v-checkbox 
            v-if="status.consent_required"
            v-model="request.consented"
            :rules="consentRules"
            >
            <template v-slot:label>
              <VueMarkdown class="markdown-content" @click="checkClickLink">{{event.join_consent_msg}}</VueMarkdown>
            </template>
          </v-checkbox>

          <div v-if="status.gender_required">
            <VueMarkdown class="markdown-content">{{event.join_gender_msg}}</VueMarkdown>
            <v-radio-group v-model="request.gender" :rules="genderRules" row class="mt-0" >
              <v-radio :label="$t('shared.gender-male')" value="M"></v-radio>
              <v-radio :label="$t('shared.gender-female')" value="F"></v-radio>
            </v-radio-group>
          </div>

          <div v-if="(event.join_force_name && profile && profile.random_name) || hasErrorInField('DISPLAY_NAME')">
            <v-text-field
              v-model="request.profile_name"
              :label="$t('events.welcome.join-enter-your-name')"
              :hint="$t('events.welcome.join-enter-your-name-hint')"
              :rules="nameRules" 
              persistent-hint
              />
          </div>

          <div v-if="(event.join_require_email && profile && !profile.email) || hasErrorInField('EMAIL')">
            <v-text-field
              v-model="request.email"
              :label="$t('events.welcome.join-enter-email')"
              :hint="$t('account.register.email-is-required')"
              :rules="emailRules" 
              persistent-hint
              />
          </div>

          <div v-if="event.join_select_team">
            <h4>{{$t('events.welcome.join-team-label')}}</h4>
            <VueMarkdown v-if="event.join_team_msg" class="markdown-content">{{event.join_team_msg}}</VueMarkdown>
            <v-radio-group v-model="request.team_join_option" :rules="selectTeamRules" class="pt-0 mt-2">
              <v-radio
                value="JOIN"
                :label="$t('events.welcome.join-team-join-a-team')"
                />
              <v-autocomplete
                v-if="request.team_join_option == 'JOIN'"
                v-model="request.group_id"
                :items="groups"
                :rules="joinTeamRules"
                item-text="name"
                item-value="id"
                class="ml-8"
                :label="$t('events.welcome.join-team-select')"
                no-filter
                />
              <v-radio 
                v-if="!event.join_block_team_create"
                value="CREATE"
                :label="$t('events.welcome.join-team-create')"
                />
                <v-text-field
                  v-if="request.team_join_option == 'CREATE'"
                  v-model="request.team_name"
                  class="ml-8"
                  :rules="createTeamRules"
                  :label="$t('events.welcome.join-team-create-name')"
                  />

              <v-radio 
                value="SKIP"
                :label="$t('events.welcome.join-team-skip')"
                />
            </v-radio-group>
          </div>

          <RegFieldInput v-model="request.udf_1" :field="event.join_udf_1_field" :eventId="event.id" />
          <RegFieldInput v-model="request.udf_2" :field="event.join_udf_2_field" :eventId="event.id" />
          <RegFieldInput v-model="request.udf_3" :field="event.join_udf_3_field" :eventId="event.id" />
          <!-- <div v-if="event.join_udf_1_field && event.join_udf_1_field.label">
            <h4>{{event.join_udf_1_field.label}}</h4>
            <p v-if="event.join_udf_1_field.hint" class="text-muted pb_0 mb_0">{{event.join_udf_1_field.hint}}</p>
            <v-radio-group v-if="event.join_udf_1_field.type == 'TEAM'" v-model="request.udf_1" :rules="selectOptionRules" class="pt-0 mt-2">
              <v-radio v-for="val in teamsForUdf1" :key="val.id"
                :value="val.id"
                :label="val.name"
                />
            </v-radio-group>
            <v-text-field 
              v-else
              v-model="request.udf_1"
              :label="event.join_udf_1_field.label"
              :rules="selectOptionRules"
              />
          </div> -->


          
          <div v-if="event.join_select_race_import_ids && event.join_select_race_import_ids.length">
            <h4>{{$t('events.welcome.join-leaderboard-label')}}</h4>
            <VueMarkdown v-if="event.join_race_msg" class="markdown-content">{{event.join_race_msg}}</VueMarkdown>
            <v-radio-group v-model="request.race_import_id" :rules="selectRaceRules" class="pt-0 mt-2">
              <v-radio v-for="race in event.join_select_race_import_ids" :key="race"
                :value="race"
                :label="race"
                />
            </v-radio-group>
          </div>

          <v-btn color="primary" :loading="$store.getters.isLoading" @click="joinEvent">{{$t('events.welcome.verify-code')}}</v-btn>
      </v-form>
      <br/>
    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import eventService from "@/services/eventService";
import groupService from "@/services/groupService";
import VueMarkdown from '@/components/VueMarkdown.vue'
import EventHeader from '@/components/EventHeader.vue';
import EventJoinCard from '@/components/EventJoinCard.vue';
import RegFieldInput from '@/components/RegFieldInput.vue';
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
import store from "@/store";
const tenant = tenants.current();
import { getAuth } from "firebase/auth";

export default {
  name: "EventJoin",
  components: {
    VueMarkdown,
    EventHeader,
    EventJoinCard,
    RegFieldInput,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      request: { code: null, gender: null, consented: false, team_join_option: null, group_id: null, team_name: null, profile_name: null, race_import_id: null, udf_1: null, udf_2: null, udf_3: null},
      groups: null,
      status: null,
      profile: null,
      result: null,
      valid: false,
      hideVerificationCode: false,
    };
  },

  async mounted() {
    await this.loadEvent(this.$route.params.id);

    EventBus.$on('login-state-change', async user => {
      await this.getEventStatus();
    });

    const auth = getAuth();
    const user = auth.currentUser;
    if (!user && !store.getters.localToken) {
      // not logged in, force back into the register flow
      this.$router.push({ name: 'register', query: { event: this.event.id, verificationCode: this.$route.query.verificationCode, joinGroupId: this.$route.query.joinGroupId } });
    }
    if (this.$route.query.joinGroupId) {
      this.request.team_join_option = "JOIN";
      //var group = (await profileService.getGroup(this.$route.query.joinGroupId)).data;
      //this.request.team_name = group && group.name;
      this.request.group_id = this.$route.query.joinGroupId;
    }
  },

  methods: {
      
    async getEventStatus() {
      if (this.user) {
        var response = await profileService.getEventStatus(this.event.id);
        this.status = response.data;
        this.profile = (await profileService.get(/*full:*/false)).data;

        this.request.code = this.$route.query.verificationCode;
        this.request.email = this.profile.email;
        this.request.profile_name = this.profile.name;
        if (this.request.code) {
          this.hideVerificationCode = true;
          if (this.canAutoJoin) {
            await this.joinEvent(null, /*force:*/true);
          }
        }
      }
    },
      
    async maybeGetTeamNames() {
      if (this.user && this.event && this.event.join_select_team) {
        var response = await groupService.getGroupsInEvent(this.event.id);
        this.groups = response.data.data;
      }
    },

    async joinEvent(e, force) {
      console.log('Trying to join with code', this.request.code, 'forced', force);
      if (force || this.$refs.form.validate()) {
        this.result = (await profileService.joinEvent(this.event.id, this.request)).data;
        if (this.result && this.result.status == "OK") {
          this.$router.replace({ name: "eventWelcome", params: { event: this.event.id} });
          return;
        }
        //await this.getEventStatus();
      }
    },
    
    async loadEvent(id) {
      var response = await eventService.get(id);
      this.event = response.data;
      await this.getEventStatus();
      await this.maybeGetTeamNames();
      var meta = {
        title: this.event.name + ' on ' + tenant.name,
        description: this.event.description,
        image: this.event.img,
      };
      EventBus.$emit('page-header-change', meta);
    },
    hasErrorInField(fieldName) {
      return this.result && this.result.status == 'ERROR' && this.result.error_fields && this.result.error_fields.includes(fieldName);
    },
    checkClickLink(event) {
      //console.log('Click markdown cnotent', event);
      if (event && event.target && event.target.localName == 'a' && event.target.href) {
        // prevent bubble so it doesn't get captured by the checkbox
        event.stopPropagation();
      }
    }

  },
  computed: {
    canAutoJoin() {
      if (this.event && this.event.verification_method == 'ALLOWLIST' && this.event.join_select_team) {
        // must select a team
        return false;
      }
      return true;
    },
    isConnected() {
      return this.status && this.status.connected;
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.$t('events.welcome.join-event'), disabled: true },
      ];
    },
    consentRules() {
      return !this.event || !this.status || !this.status.consent_required ? [] : [
        v => !!v || this.$t('events.welcome.consent-validation-msg'),
      ];
    },
    codeRules() {
      return !this.event || !this.status || !this.status.verification_required ? [] : [
        v => !!v || this.$t('events.welcome.verification-code-validation-msg'),
      ];
    },
    nameRules() {
      return !this.event || !this.event.join_force_name || !this.profile || !this.profile.random_name ? [] : [
        v => !!v || this.$t('events.welcome.join-name-validation-msg'),
      ];
    },
    emailRules() {
      //console.log('val', this.event, this.event.join_require_email, this.profile, this.profile.email=== undefined );
      return !this.event || !this.event.join_require_email || !this.profile || !(this.profile.email=== undefined) ? [] : [
        v => !!v || this.$t('account.register.email-is-required'),
        v => /.+@.+/.test(v) || this.$t('account.register.email-must-be-valid')
      ];
    },
    selectRaceRules() {
      return !this.event || !this.event.join_select_race_import_ids || !this.event.join_select_race_import_ids.length ? [] : [
        v => !!v || this.$t('shared.required-option'),
      ];
    },
    selectTeamRules() {
      return !this.event || !this.event.join_select_team ? [] : [
        v => !!v || this.$t('shared.required-option'),
      ];
    },
    selectOptionRules() {
      return [
        v => !!v || this.$t('shared.required-option'),
      ];
    },
    createTeamRules() {
      return !this.event || !this.event.join_select_team || this.request.team_join_option !== 'CREATE' ? [] : [
        v => !!v || this.$t('events.welcome.join-create-team-validation-msg'),
      ];
    },
    joinTeamRules() {
      return !this.event || !this.event.join_select_team || this.request.team_join_option !== 'JOIN' ? [] : [
        v => !!v || this.$t('events.welcome.join-join-team-validation-msg'),
      ];
    },
    genderRules() {
      return !this.event || !this.status || !this.status.gender_required ? [] : [
        v => !!v || this.$t('events.welcome.gender-validation-msg'),
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadEvent(to.params.id);
    next();
  },
};
</script>
<style lang="scss">
  .markdown-content {
    p { margin-bottom: 0 !important;}
  }
</style>

